import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Container, Row, Spinner } from "react-bootstrap";

import { Footer } from "./components/Footer";
import { SignIn } from "./components/SignIn";
import { Dashboard } from "./components/Dashboard";

import firebase from "./firebase";
import "firebase/firestore";
import "firebase/auth";

const auth = firebase.auth();
const firestore = firebase.firestore();
const collection = firestore.collection(process.env.REACT_APP_COLLECTION_NAME);

const App = () => {
  const [user, loading] = useAuthState(auth);
  return (
    <>
      <Container className="h-100">
        {!loading ? (
          <>
            {user ? (
              <Row className="pb-5 mb-5">
                <Dashboard auth={auth} collection={collection} />
              </Row>
            ) : (
              <Row className="h-100 justify-content-center align-items-center">
                <SignIn auth={auth} />
              </Row>
            )}
          </>
        ) : (
          <Row className="h-100 justify-content-center align-items-center">
            <Spinner animation="grow" variant="dark" />
          </Row>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default App;
