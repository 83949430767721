import React, { useState, useEffect } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Spinner, Col, Row } from "react-bootstrap";
import { DashboardElement } from "./DashboardElement";
import { UserInput } from "./UserInput";

import "firebase/firestore";

export const Dashboard = (props) => {
  const { collection, auth } = props;

  const query = collection.orderBy("user");
  const [users] = useCollectionData(query, { idField: "id" });
  const [user, setUser] = useState();
  const [isSearchEnded, setIsSearchEnded] = useState(false);

  useEffect(() => {
    if (users) {
      users.forEach((user) => {
        if (user.id === auth.currentUser.uid) {
          setUser(user);
        }
      });
      setIsSearchEnded(true);
    }
  }, [users]);

  return (
    <>
      {isSearchEnded ? (
        <Row className="m-3 border rounded py-3">
          <UserInput
            user={user}
            collection={collection}
            userName={auth.currentUser.displayName}
            userId={auth.currentUser.uid}
            userAvatar={auth.currentUser.photoURL}
          />
        </Row>
      ) : (
        <Col lg={12} className="pt-5">
          <Spinner animation="grow" variant="dark" />
        </Col>
      )}
      {users ? (
        users.map((user) => <DashboardElement key={user.id} user={user} />)
      ) : (
        <Col lg={12} className="pt-5">
          <Spinner animation="grow" variant="dark" />
        </Col>
      )}
    </>
  );
};
