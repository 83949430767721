import { Col, Card, Image, Row } from "react-bootstrap";
import { statusToIcon } from "../tools";

export const DashboardElement = (props) => {
  const { user, available, place, avatar, message } = props.user;

  return (
    <Col lg={3} md={4} sm={6} xs={12} className="mb-2">
      <Card>
        <Card.Body className="pt-3 pb-1 px-3">
          <Row className="nameCardContainer">
            <Col xs={9}>
              <h6>{user}</h6>
              <Card.Text className="font-italic small">
                {message ? message : "-"}
              </Card.Text>
            </Col>
            <Col xs={3} className="p-0 pr-3">
              <Image src={avatar} className="googleImage float-right" rounded />
            </Col>
          </Row>
          <hr className="mt-2 mb-2" />
          <Row>
            <Col xs={9}>
              <Card.Text className="text-muted small">Miejsce</Card.Text>
            </Col>
            <Col xs={3}>
              <Card.Text className="float-right pr-1">
                {statusToIcon(place)}
              </Card.Text>
            </Col>
          </Row>
          <Row>
            <Col xs={9}>
              <Card.Text className="text-muted small">Dostępność</Card.Text>
            </Col>
            <Col xs={3}>
              <Card.Text className="mb-1 pr-1 float-right">
                {statusToIcon(available)}
              </Card.Text>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};
