export const availableStatuses = [
  {
    icon: "check",
    description: "Dostępny",
  },
  {
    icon: "ban",
    description: "Zajęty",
  },
  {
    icon: "business-time",
    description: "Spotkanie",
  },
  {
    icon: "coffee",
    description: "Przerwa",
  },
  {
    icon: "times",
    description: "Niedostępny",
  },
];
