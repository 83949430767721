import React from "react";
import firebase from "firebase/app";
import { Button, Card, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import logo from "../favicon.png";

export const SignIn = (props) => {
  const { auth } = props;
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  };
  return (
    <Col lg={4} md={6} className="d-flex align-items-center">
      <Card className="pt-5 pb-3 mb-5 text-center">
        <Card.Img variant="top" className="mx-auto" src={logo} />
        <Card.Body className="pb-0">
          <Card.Title>Be up to date</Card.Title>
          <Card.Text>
            Dostęp do aplikacji możliwy po zalogowaniu przy użyciu konta w
            domenie <i>identt.pl</i>.
          </Card.Text>
          <Button variant="danger" className="w-50" onClick={signInWithGoogle}>
            <FontAwesomeIcon icon={faGoogle} />
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
};
