export const placeStatuses = [
  {
    icon: "laptop-house",
    description: "Praca zdalna",
  },
  {
    icon: "hotel",
    description: "Biuro Kościuszki",
  },
  {
    icon: "building",
    description: "Biuro Bałuckiego",
  },
  {
    icon: "car",
    description: "Wyjazd",
  },
  {
    icon: "minus",
    description: "Wolne",
  },
];
