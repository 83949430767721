import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faBan,
  faCheck,
  faBusinessTime,
  faBug,
  faTimes,
  faCar,
  faBuilding,
  faLaptopHouse,
  faHotel,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";

export const statusToIcon = (status) => {
  let icon;
  switch (status) {
    // Ikony dostępności
    case "ban":
      icon = <FontAwesomeIcon icon={faBan} />;
      break;
    case "check":
      icon = <FontAwesomeIcon icon={faCheck} />;
      break;
    case "business-time":
      icon = <FontAwesomeIcon icon={faBusinessTime} />;
      break;
    case "coffee":
      icon = <FontAwesomeIcon icon={faCoffee} />;
      break;
    case "times":
      icon = <FontAwesomeIcon icon={faTimes} />;
      break;
    // Ikony miejsca
    case "laptop-house":
      icon = <FontAwesomeIcon icon={faLaptopHouse} />;
      break;
    case "hotel":
      icon = <FontAwesomeIcon icon={faHotel} />;
      break;
    case "building":
      icon = <FontAwesomeIcon icon={faBuilding} />;
      break;
    case "car":
      icon = <FontAwesomeIcon icon={faCar} />;
      break;
    case "minus":
      icon = <FontAwesomeIcon icon={faMinus} />;
      break;
    default:
      icon = <FontAwesomeIcon icon={faBug} />;
      break;
  }
  return icon;
};
