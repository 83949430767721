import { Container } from "react-bootstrap";

export const Footer = () => {
  return (
    <footer id="footer" className="bg-white text-black-50">
      <Container className="container text-center py-2">
        &copy; <span>ID</span>ENTT
      </Container>
    </footer>
  );
};
