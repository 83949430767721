import React, { useEffect, useState } from "react";
import {
  Button,
  Image,
  Form,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { statusToIcon } from "../tools";
import { placeStatuses } from "../constants/placeStatuses";
import { availableStatuses } from "../constants/availableStatuses";

let timer;
const timeToUpdate = 5000;

export const UserInput = (props) => {
  const { collection, userName, userId, user, userAvatar } = props;

  const [name, setName] = useState(user?.user);
  const [available, setAvailable] = useState(user?.available);
  const [place, setPlace] = useState(user?.place);
  const [avatar, setAvatar] = useState(user?.avatar);
  const [message, setMessage] = useState(user?.message ? user.message : "");
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    clearTimeout(timer);
    if (
      changed &&
      available &&
      place &&
      (available !== user?.available ||
        place !== user?.place ||
        message !== user?.message)
    ) {
      timer = setTimeout(() => {
        name ? onUpdate() : onCreate();
      }, timeToUpdate);
    }
  }, [place, available, message]);

  const onCreate = () => {
    collection.doc(userId).set({
      user: userName,
      available,
      place,
      avatar: userAvatar,
      message,
    });
    setName(userName);
    setAvatar(userAvatar);
    setChanged(false);
  };

  const onUpdate = () => {
    collection.doc(props.user.id).set({
      user: name,
      available,
      place,
      avatar,
      message,
    });
    setChanged(false);
  };

  const setPlaceVariant = (font) => {
    return place === font ? "success" : "dark";
  };

  const setAvailableVariant = (font) => {
    return available === font ? "success" : "dark";
  };

  const setDisabled = () => {
    return place && place === "minus";
  };

  return (
    <>
      <Col md={2} sm={12} className="align-self-center">
        <Col md={12} className="d-flex justify-content-center">
          <Image
            className="googleImage mt-1"
            src={user ? avatar : userAvatar}
            rounded
          />
        </Col>
        <Col md={12} className="d-flex justify-content-center">
          <h6 className="pt-2 text-muted">{user ? name : userName}</h6>
        </Col>
      </Col>
      <Col md={4} className="align-self-center">
        <div className="d-flex justify-content-center flex-wrap">
          {placeStatuses.map((status) => {
            return (
              <OverlayTrigger
                key={status.icon}
                placement="top"
                overlay={<Tooltip>{status.description}</Tooltip>}
              >
                <Button
                  className="mt-1 mr-1 iconButton"
                  variant={setPlaceVariant(status.icon)}
                  onClick={() => {
                    setPlace(status.icon);
                    setAvailable(status.icon !== "minus" ? "check" : "times");
                    setChanged(true);
                  }}
                >
                  {statusToIcon(status.icon)}
                </Button>
              </OverlayTrigger>
            );
          })}
          <Col md={12}>
            <h6 className="pt-2 text-muted d-flex justify-content-center">
              Miejsce
            </h6>
          </Col>
        </div>
      </Col>
      <Col md={4} className="align-self-center">
        <div className="d-flex justify-content-center flex-wrap">
          {availableStatuses.map((status) => {
            return (
              <OverlayTrigger
                key={status.icon}
                placement="top"
                overlay={<Tooltip>{status.description}</Tooltip>}
              >
                <Button
                  disabled={
                    status.icon !== "times" ? setDisabled() : !setDisabled()
                  }
                  className="mt-1 mr-1 iconButton"
                  variant={setAvailableVariant(status.icon)}
                  onClick={() => {
                    setAvailable(status.icon);
                    setChanged(true);
                  }}
                >
                  {statusToIcon(status.icon)}
                </Button>
              </OverlayTrigger>
            );
          })}
          <Col md={12}>
            <h6 className="pt-2 text-muted d-flex justify-content-center">
              Dostępność
            </h6>
          </Col>
        </div>
      </Col>
      <Col md={2} className="align-self-center">
        <Form.Group className="pb-2 mb-0">
          <Form.Control
            as="textarea"
            rows={2}
            placeholder="Opis"
            value={message}
            maxLength={35}
            onChange={(e) => {
              setMessage(e.target.value);
              setChanged(true);
            }}
          />
        </Form.Group>
      </Col>
    </>
  );
};
